import React, { useRef, useEffect, useState } from "react";
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export const Map = (props) => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(undefined);

  //At init
  useEffect(() => {
    mapboxgl.accessToken = props.data.map.token

    const newMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: props.data.map.style,
      center: props.data.map.focus.center,
      zoom: props.data.map.focus.zoom,
      preserveDrawingBuffer: true //for image export - https://stackoverflow.com/questions/42483449/mapbox-gl-js-export-map-to-png-or-pdf
    });

    newMap.once('load', (e) => {
      setMap(newMap);
    });

    // Clean up on unmount
    //return () => map.remove();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //When map as been loaded
  useEffect(() => {
    if (!map) return;

    map.resize(); // fit to container

    // Add search
    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
      })
    );

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // map.on('moveend', (e) => {
    //   console.log({
    //     'center': [map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)],
    //     'zoom': map.getZoom().toFixed(2)
    //   })
    // });

    initMapLayers();

    //list all layers
    // console.log("layers list:");
    // console.log(map.getStyle().layers);
    
    if (typeof props.onMapReady === 'function') {
      props.onMapReady(map);
    }
  }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

  const initMapLayers = () => {
    //load sources
    props.data.sources.forEach(function (source) {
      // map.addSource(source.name, source.options);

      // FIXME: Ugly fix to remove not used featured to sources
      const menuIds = JSON.stringify(props.data.sidebar.tree.items);
      let options = { ...source.options };
      options.data.features = options.data.features.reduce((array, feature) => {
        if (menuIds.includes(feature.properties.menu_id)) {
          array.push(feature);
        }
        return array;
      }, []);

      map.addSource(source.name, options);
    });

    //load layers
    props.data.layers.forEach(function (layer) {
      map.addLayer(layer);
    });

    //Order layers above...
    // map.moveLayer('districts-fills','land-structure-polygon');
    // map.moveLayer('metros','road-label');
  };

  return (
    <div id="map" ref={mapContainerRef} />
  );
}
