import React, { useRef, useEffect, useState } from 'react';
import { Icon, Checkbox } from 'semantic-ui-react'
import classNames from "classnames";
import { getMenuIds } from './Utils';
import './SidebarTree.scss';


export const SidebarTree = (props) => {
  const level = props.level || 0;
  const childrenCount = (props.node.hasOwnProperty('items')) ? props.node.items.length : 0;
  const toggleColor = props.node.hasOwnProperty('color') ? props.node.color : props.parentColor;
  const showChevron = (props.node.chevron === false) ? false : true; //show or hide button to toggle show tree node
  /*
  Toggle Check
  */
  const checked = !props.disabledMenuIds.includes(props.node.menu_id);

  const handleToggleMenuIds = (menu_ids, bool, solo) => {
    if (typeof props.onToggleMenuIds === 'function') {
      props.onToggleMenuIds(menu_ids, bool, solo);
    }
  }

  const toggleCheck = (event) => {

    event.stopPropagation();

    const solo = (event.shiftKey);

    //get children menu ids
    const menuIds = getMenuIds([props.node]);

    if (typeof props.onToggleMenuIds === 'function') {
      props.onToggleMenuIds(menuIds, !checked, solo);
    }
  };


  //check for the first update
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, []);

  /*
  Menu Open ?
  */
  const nodeOpen = props.node.hasOwnProperty('open') ? props.node.open : false;
  const [open, setOpen] = useState(nodeOpen);

  const toggleOpen = () => {
    //console.log("VISIBILITY FOR " + props.node.label + ": " + (!open).toString())
    setOpen(!open);
  };

  const flyToItem = () => {
    if (!props.node.focus) return;

    props.map.flyTo({
      center: props.node.focus.center,
      zoom: props.node.focus.zoom
    });

  }

  var childNodes;

  if (childrenCount) {
    childNodes = props.node.items.map(function (node, index) {
      return (
        <li key={index}>
          <SidebarTree
            map={props.map}
            node={node}
            level={level + 1}
            open={open}
            checked={checked}
            resetFilters={props.resetFilters}
            parentColor={toggleColor}
            disabledMenuIds={props.disabledMenuIds}
            onToggleMenuIds={handleToggleMenuIds}
          />
        </li>
      );
    });

  }

  const childList = <ul>{childNodes}</ul>;


  return (
    <div
      className={classNames({
        treeNode: true,
        ["level-" + level]: true,
        open: open,
        hasChildren: (childrenCount),
        hasFocus: (props.node.focus),
        hasChevron: showChevron,
        checked: checked
      })}
      data-menu-id={props.node.menu_id}
    >
      <span className="label">
        <span
          className={classNames({
            'name': true,
            clickable: (props.node.focus)
          })}
          onClick={flyToItem}
        >
          <span className="item-toggle" onClick={toggleCheck}>
            <Checkbox toggle checked={checked} data-togglecolor={toggleColor} />
          </span>
          <span
            className={classNames({
              clickable: (props.node.focus),
            })}
          >
            {props.node.sidebar_label}
          </span>
        </span>

        <Icon className="clickable chevron up" name='angle double up' onClick={toggleOpen} />
      </span>
      {childList}
    </div>
  )
}
