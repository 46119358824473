//get map layers
//const layers = Constants.map.getStyle().layers

//toggle layer visibility
export const toggleMapLayer = (map,layer_id,visible) => {

  //console.log("FEATURES ON LAYER: " + layer_id);
  //console.log(getMapLayerFeatures(map,layer_id));

  //get map
  if (visible === undefined){
    const visibility = map.getLayoutProperty(layer_id, 'visibility');
    visible = ( ( visibility === 'visible') || (visibility === undefined) ) ? true : false;
  }

  //set map
  var newVisibility = visible ? 'visible' : 'none';

  map.setLayoutProperty(layer_id, 'visibility', newVisibility);
}

export const getMapLayerFeatures = (map,layer_id) => {
  return map.queryRenderedFeatures({ layers: [layer_id] });
}

export const getMenuIds = (nodes, menuItems=[]) => {
  nodes.forEach(({ menu_id, items }) => {
    if (menu_id) {
      menuItems.push(menu_id);
    }
    if (items) {
      getMenuIds(items, menuItems);
    }
  });
  return menuItems;
};

export const getDistrictsByMenuId = (features,menu_id) => {
  return features.filter(feature => {
    return feature.properties.menu_id === menu_id
  })
};

export const getMenuIdsFilter = (excludeIds, menuId = "menu_id") => {
  if (!excludeIds || !excludeIds.length) return;
  /*
  return [
    "match",
    [
      "get",
      "menu_id"
    ],
    excludeIds,
    false,//include?
    true
  ];
  */
  let filter = [
    "!in",
    menuId
  ];
  return filter.concat(excludeIds);
}

export const getStatusIdsFilter = (excludeIds) => {
  if (!excludeIds || !excludeIds.length) return;
  /*
  return [
    "match",
    [
      "get",
      "status"
    ],
    excludeIds,
    false,//include?
    true
  ];
  */
  let filter = [
    "!in",
    "status"
  ];
  return filter.concat(excludeIds);
}

/*
For a filters array, return the array key of the filter (by attribute)
*/
export const getFilterKeyByAttribute = (filters,attr) => {
  let key;
  for (const [i, v] of filters.entries()) {
    if ( Array.isArray(v) && (v[1] === attr) ){
      key = i;
    }
  }
  return key;
}

export const exportCanvas = (map) => {
  const img = map.getCanvas().toDataURL('image/png',1.0);
  const link = document.createElement("a");
  link.href = img;
  link.setAttribute("download", "mapSnapshot.png"); //or any other extension
  document.body.appendChild(link);
  link.click();
}
