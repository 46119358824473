import React, { useRef, useEffect, useState } from 'react';

import axios from 'axios';
import classNames from "classnames";
// import { Top } from "./Top";
// import { Bottom } from "./Bottom";
import { Sidebar } from "./Sidebar";
import { Map } from "./Map";
import { getMenuIds } from './Utils';

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import './assets/scss/main.scss';
import './App.scss';
import './App-custom.scss'; //some tweaks

const App = () => {
  const [map, setMap] = useState(undefined);

  const loaderRef = useRef(null);
  const [datas, setDatas] = useState(undefined);

  const [soloItem, setSoloItem] = useState(false);

  const [disabledMenuIds, setDisabledMenuIds] = useState([]);
  const [defaultDisabledMenuIds, setDefaultDisabledMenuIds] = useState([]);
  const [resetFiltersEnabled, setFiltersBtEnabled] = useState(false);
  const [resetFilters, setResetFilters] = useState(false);
  const [fullScreenMode, setfullScreenMode] = useState(false);


  //load data at init
  //use a 'Fat Action Creators' pattern - https://stackoverflow.com/a/49159364/782013
  React.useEffect(() => {

    const jsonDataCheck = (response) => {
      if (response.headers && response.headers['content-type'] && response.headers['content-type'].indexOf('application/json') >= 0 && (typeof response.data === 'string')) {
        return false;
      } else {
        return true;
      }
    }

    /*
    Get JSONs from 'data' url and replace it with the loaded content.
    So we can access de geoJSON data without the need of mapbox.
    */

    const fetchGeoDatas = async (datas) => {

      return new Promise((resolve, reject) => {

        let sourceLoadedCount = 0;

        datas.sources.forEach(function (source) {
          axios.get(
            source.options.data, //URL
            {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then(function (response) {
              source.options.data = response.data;
              sourceLoadedCount = sourceLoadedCount + 1;

              if (sourceLoadedCount === datas.sources.length) {
                resolve();
              }
            })
            .catch((error) => {// error
              reject(error);
            })

        });

      })
    }

    const fetchDatas = async () => {

      return new Promise((resolve, reject) => {

        //load main data
        axios.get(
          'data.json',
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(function (response) {

            if (!jsonDataCheck(response)) {
              reject('JSON error in ' + response.config.url);
            }

            let mainDatas = response.data;

            //load geo data
            fetchGeoDatas(mainDatas)
              .then(function (response) {
                resolve(mainDatas);
              })
          })
          .catch((error) => {// error
            console.error(error); // log since might be a render err

          })

      })
    }

    fetchDatas()
      .then(function (response) {
        setDatas(response);
      });

  }, []);

  //switch back to resetFilters = false as soon as it is true
  useEffect(() => {
    if (resetFilters === true) {

      setDisabledMenuIds(defaultDisabledMenuIds);
      setResetFilters(!resetFilters);
      setFiltersBtEnabled(false);
      map.flyTo({
        center: datas.map.focus.center,
        zoom: datas.map.focus.zoom
      });
    }
  }, [resetFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  //initialize datas

  useEffect(() => {
    if (!datas) return;

    const getClosedMenuIds = (nodes, menuItems = []) => {
      nodes.forEach((node) => {

        if (node.menu_id) {
          if (node.checked === false) {
            menuItems.concat(getMenuIds([node], menuItems));
          }
        }
        if (node.items) {
          getClosedMenuIds(node.items, menuItems);
        }

      });
      return menuItems;
    };

    const closedMenuIds = getClosedMenuIds([datas.sidebar.tree]);
    setDefaultDisabledMenuIds(closedMenuIds);
    setDisabledMenuIds([...closedMenuIds]);

  }, [datas]);

  //when disabled menus are updated, enable "reset filters" button
  useEffect(() => {
    const isDefaultMenus = (disabledMenuIds.length === defaultDisabledMenuIds.length);
    const equal = isDefaultMenus;

    setFiltersBtEnabled(!equal);
  }, [disabledMenuIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const fullScreenHandle = useFullScreenHandle();
  const reportFullScreenChange = React.useCallback((state, handle) => {
    if (handle === fullScreenHandle) {
      setfullScreenMode(state);
      //console.log('Screen went to fullscreen?', state, handle);
    }
  }, [fullScreenHandle]);

  const onResetFilters = (value) => {
    setResetFilters(true);
  }

  const handleToggleMenuIds = (menu_ids, bool, solo) => {
    let result = [...disabledMenuIds];

    const currentItem = menu_ids[0];
    const wasActive = !disabledMenuIds.includes(currentItem);
    const wasSoloMode = (soloItem === currentItem);

    if (solo) {
      setSoloItem(currentItem);
    } else {
      setSoloItem(undefined);
    }

    if (solo) {
      const allItems = result = getMenuIds([datas.sidebar.tree]);
      bool = ((!bool && !wasSoloMode) || bool) ? true : false;

      if (bool) { //disable all but this ones
        result = allItems.filter(x => !menu_ids.includes(x));

      } else { //enable all but this ones
        result = menu_ids;
      }

    } else {
      if (!wasActive) {
        //remove from array
        result = result.filter(x => !menu_ids.includes(x));
      } else {
        result = result.concat(menu_ids);
        result = [...new Set(result)]; //unique
      }
    }

    setDisabledMenuIds(result);
  }

  return (
    <>
      <FullScreen handle={fullScreenHandle} onChange={reportFullScreenChange}>
        <div
          id="loader"
          ref={loaderRef}
          className={classNames({
            active: !map
          })}
        >
          <p>
            <img src="logo.svg" alt="Neon Factory" />
          </p>
        </div>

        <div className="rows">
          {/* <Top fullScreen={fullScreenMode} fullScreenHandle={fullScreenHandle} onResetFilters={onResetFilters} resetFilters={resetFiltersEnabled} map={map} /> */}
          <div id="main" className="columns">
            {datas &&
              <Sidebar
                active="true"
                map={map}
                data={datas.sidebar}
                disabledMenuIds={disabledMenuIds}
                resetFilters={resetFilters}
                onToggleMenuIds={handleToggleMenuIds}

                fullScreen={fullScreenMode}
                fullScreenHandle={fullScreenHandle}
                resetFiltersEnabled={resetFiltersEnabled}
                onResetFilters={onResetFilters}
              />
            }
            {datas &&
              <Map
                data={datas}
                disabledMenuIds={disabledMenuIds}
                onMapReady={(map) => { setMap(map) }}
              />
            }
          </div>
          {/* {datas &&
            <Bottom captionData={datas.captions} />
          } */}
        </div>
      </FullScreen>
    </>
  );
};

export default App;
