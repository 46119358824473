import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Icon, Button } from 'semantic-ui-react';
import { SidebarTree } from './SidebarTree';
import { getMenuIdsFilter, getFilterKeyByAttribute, exportCanvas } from './Utils';

export const Sidebar = (props) => {
  const [isActive, setisActive] = useState(props.active);

  const toggleItemsByMenuId = (closedMenuIds) => {
    if (!props.map) return;

    const layers = [
      'districts-fills',
      'districts-strokes',
      'districts-labels',
      // 'metros',

      'districts',
      'districts-outline',
      'communes',
      'communes-outline',
      'metro-lines',
      'metro-stations',
      'villo',
      'parking',
      'gares'
    ];

    let menuFilter = getMenuIdsFilter(closedMenuIds);
    layers.forEach(function (layer_id) {
      var mapLayer = props.map.getLayer(layer_id);
      if (typeof mapLayer !== undefined) {
        let currentFilters = props.map.getFilter(layer_id) || ['all'];
        let newFilters = [...currentFilters];

        //remove current filter if any
        let menuIdFilterKey = getFilterKeyByAttribute(currentFilters, 'menu_id');
        if (menuIdFilterKey) {
          newFilters.splice(menuIdFilterKey, 1);
        }

        //append new filter
        if (menuFilter) {
          newFilters = newFilters.concat([menuFilter]);
        }

        props.map.setFilter(layer_id, newFilters);
      }
    });

    // FIXME: features take sidebar_id and not menu_id on MapBox
    menuFilter = getMenuIdsFilter(closedMenuIds, 'sidebar_id');
    layers.forEach(function (layer_id) {
      var mapLayer = props.map.getLayer(layer_id);
      if (typeof mapLayer !== undefined) {
        let currentFilters = props.map.getFilter(layer_id) || ['all'];
        let newFilters = [...currentFilters];

        //remove current filter if any
        let menuIdFilterKey = getFilterKeyByAttribute(currentFilters, 'sidebar_id');
        if (menuIdFilterKey) {
          newFilters.splice(menuIdFilterKey, 1);
        }

        //append new filter
        if (menuFilter) {
          newFilters = newFilters.concat([menuFilter]);
        }

        props.map.setFilter(layer_id, newFilters);
      }
    });

    // Added for new layers
    layers.forEach((layerId) => {
      // FIXME: temporary workaround to keep the various filters working properly
      if (closedMenuIds.includes(layerId) && layerId !== 'districts') {
        props.map.setLayoutProperty(layerId, 'visibility', 'none');
      }
      else {
        props.map.setLayoutProperty(layerId, 'visibility', 'visible');
      }
    });

    // console.log('features filter by district / on layers', menuFilter, layers);
  }

  //disable districts by menu ID
  useEffect(() => {
    toggleItemsByMenuId(props.disabledMenuIds);
  }, [props.disabledMenuIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleActive = () => {
    setisActive(!isActive);
    setTimeout(() => {
      //console.log("sidebar toggled");
      props.map.resize(); // fit to container
    }, 500);
  }

  const handleToggleMenuIds = (menu_ids, bool, solo) => {
    if (typeof props.onToggleMenuIds === 'function') {
      props.onToggleMenuIds(menu_ids, bool, solo);
    }
  }

  const doExport = () => {
    exportCanvas(props.map);
  }

  //reset button clicked
  const setFilters = (e) => {
    if (typeof props.onResetFilters === 'function') {
      props.onResetFilters(true);
    }
  }

  return (
    <div id="sidebar"
      className={classNames({
        active: isActive
      })}
    >
      <div id="sidebar-content">
        <div id="sidebar-logo">
          <img src="logo-whiteText.svg" alt="Neon Factory" />
        </div>
        <div className="container-buttons">
          <Button
            content='Reset map'
            className={classNames({
              clickable: true,
              active: true
            })}
            onClick={setFilters}
          />
        </div>

        <SidebarTree
          node={props.data.tree}
          map={props.map}
          root={true}
          resetFilters={props.resetFilters}
          disabledMenuIds={props.disabledMenuIds}
          onToggleMenuIds={handleToggleMenuIds}
        />

        <div className="container-buttons">
          {/* <Button
            content='Reset filters'
            icon='options'
            id="filters-bt"
            className={classNames({
              clickable: true,
              active: props.resetFiltersEnabled
            })}
            onClick={setFilters}
          /> */}

          {!props.fullScreen &&
            <Button
              content='Full Screen'
              icon='expand'
              id="fullscreen-bt"
              className="clickable"
              onClick={props.fullScreenHandle.enter}
            />
          }
          {props.fullScreen &&
            <Button
              content='Exit Full Screen'
              icon='compress'
              id="fullscreen-bt"
              className="clickable"
              onClick={props.fullScreenHandle.exit}
            />
          }

          <Button
            content='Download your map'
            icon='download'
            id="export-bt"
            onClick={doExport}
          />
        </div>
      </div>
      <div id="sidebar-handle-bar" className="clickable" onClick={toggleActive} >
        <div id="sidebar-handle">
          {isActive ? <Icon name='chevron left' /> : <Icon name='chevron right' />}
        </div>
      </div>
    </div>
  );
}
